<template>
    <modal ref="modalDetallesPedidos" :img="producto.imagen_firmada" :titulo="`${producto.sku} - ${producto.nombre}`" no-aceptar eliminar @eliminar="eliminarRecurrenteProducto">
        <div class="col-11 mx-auto">
            <div class="col-12 mb-3 text-center">
                <div class="d-flex justify-center mb-2">
                    <p class="text-general f-12 f-500 mr-3">{{ producto.id_promocion ? separadorNumero(producto.promo_valor) : separadorNumero(producto.valor) }}</p>
                    <p v-if="producto.id_promocion" class="precio_descuento text-whitesmoke f-12 f-500">{{ separadorNumero(producto.valor) }}</p>
                </div>
                <div class="d-flex justify-center ">
                    <p v-if="producto.id_promocion" class="bg-leeche text-white tipo_descuento">{{ producto.texto }}</p>
                </div>
            </div>

            <p class="text-general f-15 mb-2 f-200">{{ producto.descripcion }}</p>
            <p class="f-15 f-500 mb-2 text-general"> Presentacion: <span class="text-general f-15 f-400">{{ producto.presentacion }}</span></p>
            <p class="f-15 f-500 mb-2 text-general"> Unidad de medida: <span class="text-general f-15 f-400">{{ producto.unidad }}</span></p>
            <p class="f-15 f-500 text-general"> Venta minima: <span class="text-general f-15 f-400">{{ producto.cantidad_minima }}</span></p>
            <div class="d-flex my-3 align-items-center justify-content-between">
                <p class="f-15 f-500"> Cantidad pedida: <el-input-number v-model="producto.cantidad" :min="1" :max="Infinity" size="mini" class="card-producto__contador" @change="gestionRecurrenteProducto(producto)" /></p>
                <p class="f-15 f-500 ">Total: <span class="f-15 f-400 text-general">{{ separadorNumero(producto.total) }}</span></p>
            </div>
        </div>
    </modal>
</template>

<script>
import PedidosRecurrentes from '~/services/almacen/pedidos_recurrentes'
export default {

    data(){
        return{
            valorUnidad:0,
            pedidoActivo:0,
            producto:{}
        }
    },
    methods:{
        NuevoValor(val){
            console.log(val)
        },
        toggle(producto, pedidoActivo){
            this.producto = {...producto}
            this.pedidoActivo = pedidoActivo
            this.$refs.modalDetallesPedidos.toggle()
        },
        async gestionRecurrenteProducto(producto){
            try {
                let model = {
                    id_recurrente: this.pedidoActivo,
                    id_producto: producto.id,
                    cantidad: producto.cantidad,
                }
                const {data} = await PedidosRecurrentes.gestionRecurrenteProducto(model)
                this.notificacion('Mensaje', data.message, 'success')
                // this.activa = !this.activa
                this.$emit('agregarProducto', data.data)

            } catch (e){
                this.error_catch(e)
            }
        },
        async eliminarRecurrenteProducto(){
            try {

                const { data } = await PedidosRecurrentes.eliminarRecurrenteProducto(this.producto.id_recurrente_producto)
                this.notificacion('Mensaje', data.message, 'success')
                // this.activa = !this.activa
                let model = {
                    recurrente: data.data,
                    id_recurrente_producto:this.producto.id_recurrente_producto
                }
                this.$emit('eliminarProducto', model)
                this.$refs.modalDetallesPedidos.toggle()


            } catch (e){
                this.error_catch(e)
            }
        },
    },
}

</script>

<style lang="scss" scoped>
.posicionar{
    position: relative;
    bottom: 10px;
    z-index: 1;
}
.precio_descuento{
    text-decoration: line-through
}
.tipo_descuento{
    border-radius: 22px;
    font-size: 11px;
    color: #fff;
    height: 17px;
    text-align: center;
    text-transform: uppercase;
    padding:  2px 10px 0;
    width: fit-content;
}
</style>
